import React, { Component } from "react"

// Render
class ScrollOnMount extends Component {
  constructor() {
    super()
    this.wrapper = React.createRef()
  }

  componentDidMount() {
    window.scrollTo({
      top: this.wrapper.current.offsetTop,
      left: 0,
      behavior: "smooth",
    })
  }
  render() {
    return <div ref={this.wrapper} {...this.props} />
  }
}

export default ScrollOnMount
