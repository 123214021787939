import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import {
  weddingPartyMember,
  weddingPartyContent,
  contentRight,
  weddingPartyMemberImage,
} from "../weddingPartyMember"

const imageWrapper = css`
  position: relative;
  top: -10px;

  @media screen and (min-width: 680px) {
    position: absolute;
    top: -40px;
    left: -70px;
  }
  @media screen and (min-width: 860px) {
    width: 421px;
  }
`

const Jeremy = props => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "jeremy.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 421) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div css={weddingPartyMember}>
      <div css={[weddingPartyMemberImage, imageWrapper]}>
        <Img
          fluid={data.file.childImageSharp.fluid}
          imgStyle={{ objectFit: "contain" }}
          {...props}
        />
      </div>
      <div css={[weddingPartyContent, contentRight]}>
        <div>
          <h2>Jeremy Franz</h2>
          <h4>Best Man</h4>
          <p>
            Friends since before birth. The only reason Mikey signed up for
            preschool, but not enough reason to keep him from dropping out.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Jeremy
