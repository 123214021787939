import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import {
  weddingPartyMember,
  weddingPartyContent,
  contentRight,
  weddingPartyMemberImage,
} from "../weddingPartyMember"

const imageWrapper = css`
  position: relative;
  width: 340px;

  @media screen and (min-width: 500px) {
    top: -10px;
  }

  @media screen and (min-width: 680px) {
    position: absolute;
    top: -40px;
    left: -100px;
  }

  @media screen and (min-width: 860px) {
    width: 501px;
  }
`

const Maca = props => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "maca.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 501) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div css={weddingPartyMember}>
      <div css={[weddingPartyMemberImage, imageWrapper]}>
        <Img
          fluid={data.file.childImageSharp.fluid}
          imgStyle={{ objectFit: "contain" }}
          {...props}
        />
      </div>
      <div css={[weddingPartyContent, contentRight]}>
        <div>
          <h2>Rifeta &ldquo;Maća&rdquo; Kajdić</h2>
          <p>
            Many angsty haircuts this cousin had to endure, at the trust and
            hands of Arijana, who was neither trained nor talented for such a
            trade.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Maca
