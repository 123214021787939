import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import {
  weddingPartyMember,
  weddingPartyContent,
  contentRight,
  weddingPartyMemberImage,
} from "../weddingPartyMember"

const imageWrapper = css`
  position: relative;
  width: 360px;

  @media screen and (min-width: 500px) {
    top: -25px;
  }

  @media screen and (min-width: 680px) {
    position: absolute;
    left: -100px;
    top: 0;
  }

  @media screen and (min-width: 860px) {
    width: 513px;
    left: -150px;
  }
`

const Aida = props => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "aida.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 513) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div css={weddingPartyMember}>
      <div css={[weddingPartyMemberImage, imageWrapper]}>
        <Img
          fluid={data.file.childImageSharp.fluid}
          imgStyle={{ objectFit: "contain" }}
          {...props}
        />
      </div>
      <div css={[weddingPartyContent, contentRight]}>
        <div>
          <h2>Aida Filipović</h2>
          <p>
            Childhood friend that folk danced her way into Arijana&rsquo;s
            heart, all the way from Utah. They continued to strengthen their
            friendship with AOL Instant Messenger.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Aida
