import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import {
  weddingPartyMember,
  weddingPartyContent,
  contentRight,
  weddingPartyMemberImage,
} from "../weddingPartyMember"

const imageWrapper = css`
  @media screen and (min-width: 680px) {
    position: absolute;
    top: -20;
    left: -100px;
  }

  @media screen and (min-width: 860px) {
    width: 442px;
  }
`

const Scott = props => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "scott.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 442) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div css={weddingPartyMember}>
      <div css={[weddingPartyMemberImage, imageWrapper]}>
        <Img
          fluid={data.file.childImageSharp.fluid}
          imgStyle={{ objectFit: "contain" }}
          {...props}
        />
      </div>
      <div css={[weddingPartyContent, contentRight]}>
        <div>
          <h2>Scott Fletcher</h2>
          <p>
            Mikey &amp; Scott share humble Costa Mesa beginnings where they cut
            their (baby) teeth in the apparel industry. Scott taught Mikey the
            ways of the screen printing press, and Catalina Fish Kitchen nachos.
            Loves a good pun.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Scott
