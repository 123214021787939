import React from "react"
import ScrollOnMount from "../ScrollOnMount"
import Jeremy from "./Jeremy"
import Jon from "./Jon"
import Scott from "./Scott"
import Blair from "./Blair"
import Amir from "./Amir"
import Nick from "./Nick"
import Jeff from "./Jeff"

// Render
const His = () => (
  <ScrollOnMount>
    <Jeremy />
    <Jon />
    <Scott />
    <Blair />
    <Amir />
    <Nick />
    <Jeff />
  </ScrollOnMount>
)

export default His
