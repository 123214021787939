import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import {
  weddingPartyMember,
  weddingPartyContent,
  contentRight,
  weddingPartyMemberImage,
} from "../weddingPartyMember"

const imageWrapper = css`
  position: relative;
  top: 20px;

  @media screen and (min-width: 680px) {
    position: absolute;
    top: -20px;
    left: -110px;
  }
  @media screen and (min-width: 860px) {
    width: 438px;
  }
`

const Jeff = props => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "jeff.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 438) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div css={weddingPartyMember}>
      <div css={[weddingPartyMemberImage, imageWrapper]}>
        <Img
          fluid={data.file.childImageSharp.fluid}
          imgStyle={{ objectFit: "contain" }}
          {...props}
        />
      </div>
      <div css={[weddingPartyContent, contentRight]}>
        <div>
          <h2>Jeff Friesen</h2>
          <p>
            A childhood friend with a wealth of stories well beyond his years.
            Known for providing large bounties of fresh tuna after his deep sea
            fishing trips.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Jeff
