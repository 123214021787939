import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import {
  weddingPartyMember,
  weddingPartyContent,
  contentRight,
  weddingPartyMemberImage,
} from "../weddingPartyMember"

const imageWrapper = css`
  @media screen and (min-width: 680px) {
    position: absolute;
    top: 20px;
    left: -110px;
  }

  @media screen and (min-width: 860px) {
    width: 468px;
  }
`

const Amir = props => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "amir.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 468) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div css={weddingPartyMember}>
      <div css={[weddingPartyMemberImage, imageWrapper]}>
        <Img
          fluid={data.file.childImageSharp.fluid}
          imgStyle={{ objectFit: "contain" }}
          {...props}
        />
      </div>
      <div css={[weddingPartyContent, contentRight]}>
        <div>
          <h2>Amir Kajdić</h2>
          <p>
            A lover of gangster rap with a sensitive side, much like Mikey
            himself. Amir &amp; Mikey have been through all of the feels while
            attending the 2018 World Series game 3, where the Dodgers beat the
            Red Sox at home with an 18th inning walk-off home run (the longest
            World Series game in history), but still no title.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Amir
