import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import {
  weddingPartyMember,
  weddingPartyContent,
  contentLeft,
  weddingPartyMemberImage,
} from "../weddingPartyMember"

const imageWrapper = css`
  @media screen and (min-width: 680px) {
    position: absolute;
    top: 0;
    right: -80px;
  }

  @media screen and (min-width: 860px) {
    width: 436px;
  }
`

const Jon = props => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "jon.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 436) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div css={weddingPartyMember}>
      <div css={[weddingPartyMemberImage, imageWrapper]}>
        <Img
          fluid={data.file.childImageSharp.fluid}
          imgStyle={{ objectFit: "contain" }}
          {...props}
        />
      </div>
      <div css={[weddingPartyContent, contentLeft]}>
        <div>
          <h2>Jon Wills</h2>
          <p>
            Older brother from the same mother, although you might suspect
            another. Mikey's former wrestling opponent, but these days he faces
            off regularly against his daughters, the flower girls, Reese &amp;
            Quinn.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Jon
