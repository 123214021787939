import React from "react"
import ScrollOnMount from "../ScrollOnMount"
import Flora from "./Flora"
import Erica from "./Erica"
import Maca from "./Maca"
import Armina from "./Armina"
import Kim from "./Kim"
import Adnana from "./Adnana"
import Aida from "./Aida"

// Render
const Hers = () => (
  <ScrollOnMount>
    <Flora />
    <Erica />
    <Maca />
    <Armina />
    <Kim />
    <Adnana />
    <Aida />
  </ScrollOnMount>
)

export default Hers
