import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import {
  weddingPartyMember,
  weddingPartyContent,
  contentRight,
  weddingPartyMemberImage,
} from "../weddingPartyMember"

const imageWrapper = css`
  position: relative;
  width: 420px;

  @media screen and (min-width: 500px) {
    width: 450px;
    top: -40px;
  }

  @media screen and (min-width: 680px) {
    width: 500px;
    position: absolute;
    top: 0;
    left: -160px;
  }

  @media screen and (min-width: 860px) {
    width: 555px;
  }
`

const Kim = props => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "kim.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 555) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div css={weddingPartyMember}>
      <div css={[weddingPartyMemberImage, imageWrapper]}>
        <Img
          fluid={data.file.childImageSharp.fluid}
          imgStyle={{ objectFit: "contain" }}
          {...props}
        />
      </div>
      <div css={[weddingPartyContent, contentRight]}>
        <div>
          <h2>Kim Mora</h2>
          <p>
            College best friend / soul mate who vibrates higher than most people
            can fathom at 6am. Ari always dreamt of wearing Fubu. Kim did.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Kim
