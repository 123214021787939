import React, { useState } from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Header from "../components/Header"
import NextPage from "../components/NextPage"
import Button from "../components/Button"
import Card from "../components/Card"
import RoseImage from "../components/RoseImage"
import GeriArt from "../components/GeriArt"
import His from "../components/his/index"
import Hers from "../components/hers/index"
import { smallContainer } from "../components/container"
import { colors, fonts } from "../theme"

// Hero Section

const StyledCard = styled(Card)`
  position: relative;
  padding-bottom: 30px;
`

const HeroCard = styled(StyledCard)`
  margin: 50px auto 130px auto;
`

const heroHeadline = css`
  font-size: 36px;
  position: relative;
  z-index: 100;
  top: -25px;
  margin-bottom: 0;
`

const heroText = css`
  position: relative;
  z-index: 100;
  font-size: 18px;
  line-height: 1.3;
  color: ${colors.roseDark};
  max-width: 600px;
  margin: 20px 0;
`

const heroImage = css`
  position: absolute;
  z-index: 1;
  width: 300px;
  bottom: -140px;
  right: -60px;

  @media (min-width: 420px) {
    bottom: -100px;
  }

  @media (min-width: 500px) {
    width: 440px;
    bottom: -200px;
    right: -100px;
  }

  @media (min-width: 860px) {
    bottom: -140px;
    right: -140px;
  }
`
// Officiant Section
const officiantSection = css`
  margin: 80px auto;
`

const officiantHeadline = css`
  font-size: 36px;
  text-align: center;
`

const officiantArt = css`
  max-width: 480px;
  margin: 40px auto 120px auto;
`

const officiantName = css`
  font-size: 28px;
  font-family: ${fonts.body};
  font-style: normal;
  font-weight: 400;
  text-align: center;

  @media screen and (min-width: 490px) {
    font-size: 36px;
  }
`

const officiantBio = css`
  color: ${colors.roseDark};
  text-align: center;
`

// Wedding Party Card

const weddingPartyHeadline = css`
  font-size: 32px;
  text-align: center;
  position: relative;
  z-index: 100;
  top: -25px;
  margin-bottom: 0;

  @media (min-width: 400px) {
    font-size: 36px;
  }
`

const weddingPartyNav = css`
  height: 100px;
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    flex: 1 1 auto;
    text-align: center;
    padding-bottom: 30px;
  }

  button {
    font-size: 36px;
    width: 75px;
    padding: 0;
    text-align: left;

    &:after {
      height: 0.35em;
      bottom: 0.5rem;
      left: 0.4rem;
      background-color: ${colors.roseLight};
    }

    &:hover:after {
      bottom: 0.4rem;
      left: 0.6rem;
    }

    @media (min-width: 500px) {
      font-size: 42px;
      width: 88px;
    }
  }
`

const activeButton = css`
  &:after {
    background-color: ${colors.accent} !important;
  }
`

const weddingPartyContent = css`
  margin: 50px auto;
`

// Render
const WeddingParty = () => {
  const [activeParty, setActiveParty] = useState(false)

  return (
    <Layout>
      <SEO title="Our Party" />
      <Header />
      <div css={smallContainer}>
        <HeroCard>
          <h1 css={heroHeadline}>Our Party</h1>
          <p css={heroText}>
            Former roommates, teammates, playmates, and general renegades
            &mdash; these are the people in our lives that have enriched our
            experiences, from childhood to present, and we are endlessly
            grateful for their blind love and enthusiasm to be by our side all
            of these years.
          </p>
          <div css={heroImage}>
            <RoseImage />
          </div>
        </HeroCard>
        <div css={officiantSection}>
          <h2 css={officiantHeadline}>Our Officiant</h2>
          <div css={officiantArt}>
            <GeriArt />
            <h2 css={officiantName}>Geri Fletcher</h2>
            <p css={officiantBio}>
              An extraordinary Jill of all trades! A bird of paradise! A one of
              a kind hostess, who gently tamed Mikey&rsquo;s hair before taming
              Ari&rsquo;s heart. Always makes time to talk herbs &amp; spices.
            </p>
          </div>
        </div>
        <StyledCard>
          <h2 css={weddingPartyHeadline}>The Unofficial Wedding Party</h2>
          <div css={weddingPartyNav}>
            <div>
              <Button
                onClick={() => setActiveParty("his")}
                css={activeParty === "his" ? activeButton : null}
              >
                His
              </Button>
            </div>
            <div>
              <Button
                onClick={() => setActiveParty("hers")}
                css={activeParty === "hers" ? activeButton : null}
              >
                Hers
              </Button>
            </div>
          </div>
        </StyledCard>
      </div>
      <div css={weddingPartyContent}>
        {activeParty === "his" && <His />}
        {activeParty === "hers" && <Hers />}
      </div>
      <NextPage to="/where-to-stay">Where to Stay</NextPage>
    </Layout>
  )
}

export default WeddingParty
