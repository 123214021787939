import { css } from "@emotion/core"
import { colors, fonts, containers } from "../theme"

const backgroundImage = {
  ltr: `url("data:image/svg+xml,%3Csvg width='670' height='334' viewBox='0 0 670 334' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0L670 99.5L589 241L47.5 334L0 0Z' fill='url(%23paint0_linear)' fill-opacity='0.65'/%3E%3Cpath d='M588.366 240.094L48.3451 332.84L1.17873 1.18602L668.405 100.274L588.366 240.094Z' stroke='url(%23paint1_linear)' stroke-opacity='0.45' stroke-width='2'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='670' y1='95.5' x2='-1.84154e-06' y2='1.29197e-05' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='white'/%3E%3Cstop offset='0.40625' stop-color='white' stop-opacity='0'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear' x1='348.5' y1='54.5' x2='44.0001' y2='334' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.0364583' stop-color='%23BE7171' stop-opacity='0.27'/%3E%3Cstop offset='0.427083' stop-color='%23BE7171' stop-opacity='0.08'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E")`,
  rtl: `url("data:image/svg+xml,%3Csvg width='670' height='334' viewBox='0 0 670 334' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M670 0L0 99.5L81 241L622.5 334L670 0Z' fill='url(%23paint0_linear)' fill-opacity='0.65'/%3E%3Cpath d='M81.6337 240.094L621.655 332.84L668.821 1.18602L1.59534 100.274L81.6337 240.094Z' stroke='url(%23paint1_linear)' stroke-opacity='0.45' stroke-width='2'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='-1.7594e-05' y1='95.5' x2='670' y2='1.29197e-05' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='white'/%3E%3Cstop offset='0.40625' stop-color='white' stop-opacity='0'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear' x1='321.5' y1='54.5' x2='626' y2='334' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.0364583' stop-color='%23BE7171' stop-opacity='0.27'/%3E%3Cstop offset='0.427083' stop-color='%23BE7171' stop-opacity='0.08'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");`,
}

export const weddingPartyMember = css`
  position: relative;
  max-width: ${containers.small}px;
  margin: auto;

  p {
    color: ${colors.roseDark};

    @media screen and (min-width: 490px) {
      font-size: 18px;
    }
  }

  h2 {
    font-size: 28px;
    font-family: ${fonts.body};
    font-style: normal;
    font-weight: 400;
    text-align: center;

    @media screen and (min-width: 490px) {
      font-size: 36px;
    }
  }

  h4 {
    font-size: 18px;
    font-family: ${fonts.body};
    font-style: normal;
    font-weight: 400;
    margin-top: -24px;
    margin-bottom: 24px;
    text-align: center;
  }

  @media screen and (min-width: 860px) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    flex-direction: row;
  }
`
export const weddingPartyContent = css`
  display: flex;
  align-items: center;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1000px;
  height: 400px;
  margin-top: -20%;
  padding: 20px;

  > div {
    max-width: 520px;
  }

  @media screen and (min-width: 680px) {
    background-size: auto;
    margin-top: 0;
  }
`

export const contentLeft = css`
  background-image: ${backgroundImage.rtl};
  background-position: center left -90px;

  @media screen and (min-width: 680px) {
    background-position: center left;
  }

  > div {
    margin: auto;

    @media screen and (min-width: 680px) {
      margin-left: 10%;
      margin-right: auto;
    }
  }
`

export const contentRight = css`
  background-image: ${backgroundImage.ltr};
  background-position: center right -90px;

  @media screen and (min-width: 680px) {
    background-position: center right;
  }

  > div {
    margin: auto;

    @media screen and (min-width: 680px) {
      margin-right: 10%;
      margin-left: auto;
    }
  }
`

export const weddingPartyMemberImage = css`
  /* width: 30% !important; */
  width: 20em;
  height: 240px;
  margin: auto;

  @media screen and (min-width: 680px) {
    width: 50%;
    /* transform: translateY(-10%); */
  }
`
