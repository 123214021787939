import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import {
  weddingPartyMember,
  weddingPartyContent,
  contentLeft,
  weddingPartyMemberImage,
} from "../weddingPartyMember"

const imageWrapper = css`
  @media screen and (min-width: 680px) {
    position: absolute;
    top: -40px;
    right: -90px;
  }

  @media screen and (min-width: 860px) {
    width: 437px;
  }
`

const Nick = props => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "nick.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 437) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div css={weddingPartyMember}>
      <div css={[weddingPartyMemberImage, imageWrapper]}>
        <Img
          fluid={data.file.childImageSharp.fluid}
          imgStyle={{ objectFit: "contain" }}
          {...props}
        />
      </div>
      <div css={[weddingPartyContent, contentLeft]}>
        <div>
          <h2>Nick Burgess</h2>
          <p>
            Fathered Mikey through his 20s in Costa Mesa, imparting great wisdom
            to an asipring designer/adult. Now fathering an even stronger pupil,
            Niko. Loves his reflection, and not afraid to admit it. Eyebrows on
            fleek.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Nick
